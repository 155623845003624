<template>
  <div>
    <section class="section-watch grid-watch" id="certificate">
      <!-- LEFT -->
      <div class="watch-content watch-left">
        <router-link :to="{ name: 'MyWatch', params: { watchId: watchId } }" class="jbtn-watch-link">
          <!-- {{ $C.MODELS_INDEX[watch.model] }} -->
          <img id="watch-model-logo" :src="model.assets.logo" alt="" />
        </router-link>
        <div @click="showMenu=true" id="show-menu" key="show" class="jbtn jbtn-text show-l" v-bind:class="{'hide-menu': showMenu,}"><i class="fal fa-chevron-down"></i> Show Menu</div>
        <div @click="showMenu=false" id="hide-menu" key="hide" class="jbtn jbtn-text" v-bind:class="{'collapse': !showMenu,}"><i class="fal fa-chevron-up"></i> Hide Menu</div>
        <div class="watch-menu" v-bind:class="{'hide-l': true, 'show-menu': showMenu,}">
          <router-link
            :to="{ name: 'Certificate', params: { id: watchId } }"
            class="jbtn jbtn-watch-nav active"
          >
            Info
          </router-link>
          <router-link
            :to="{ name: 'ServicesList', params: { watchId: watchId } }"
            class="jbtn jbtn-watch-nav"
          >
            Servicing
          </router-link>
          <router-link :to="{ name: 'ServiceHistory', params: { id: watchId } }" class="jbtn jbtn-watch-nav"
            >Service History</router-link
          >
          <!-- <router-link :to=" { name: 'PaymentsWatch', params: { id: watchId } }" class="jbtn jbtn-watch-nav">Payments</router-link>
        <router-link :to=" { name: 'Tutorials', params: { id: watchId } }" class="jbtn jbtn-watch-nav">Tutorials</router-link> -->
          <router-link :to="{ name: 'Warranty', params: { id: watchId } }" class="jbtn jbtn-watch-nav"
            >Warranty</router-link
          >

          <div v-if="watch.serial" class="mywatch-content jbtn-watch-link watch-right-serial">
            <small>SERIAL</small><br />
            {{ watch.serial }}
          </div>
        </div>
      </div>

      <!-- RIGHT -->
      <div class="watch-content watch-right">
        <div class="jcard-dark jcard-watch-info">
          <div class="jcard-title">
            <h1>Watch Info</h1>
          </div>
          <div class="jcard-watch-info-grid">
            <!-- column 1 -->
            <div class="jcard-watch-info-item1">
              <div class="jcard-info-item">
                <p class="p1-platform">Status</p>
                <span class="jbtn jbtn-green jbtn-status">Owned</span>
              </div>
              <div class="jcard-info-item">
                <p class="p1-platform">Certificate</p>
                <span @click="generatePdf()" class="jbtn jbtn-grey">
                  <span v-if="isGeneratingPdf" key="spinner"
                    ><i class="fad fa-spinner-third fa-spin"></i
                  ></span>
                  <span v-else key="button">Download</span></span
                >
              </div>
            </div>
            <!-- column 2 -->
            <div>
              <div class="jcard-info-item">
                <!-- <p class="p1-platform"><span class="p-grey">Model</span> {{ model.name }}</p> -->
                <p class="p1-platform p-grey">Model</p>
                <p class="p1-platform">{{ model.name }}</p>
              </div>
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Serial</p>
                <p class="p1-platform">{{ watch.serial }}</p>
              </div>
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Limited Edition</p>
                <p class="p1-platform">1 of 8</p>
              </div>
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Warranty Expiry</p>
                <p class="p1-platform">
                  {{ $moment($C.getDateFromTimestamp(watch.expiry)).format("MM/YYYY") }}
                </p>
              </div>
            </div>
            <!-- column 3 -->
            <div>
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Purchase Date</p>
                <p class="p1-platform">
                  {{ $moment($C.getDateFromTimestamp(watch.purchasedOn)).format("DD/MM/YYYY") }}
                </p>
              </div>
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Purchase Country</p>
                <p class="p1-platform">{{ watch.purchasedAt }}</p>
              </div>
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Owner's Name</p>
                <p class="p1-platform">{{ user.firstName }} {{ user.lastName }}</p>
              </div>
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Technical Info</p>
                <a href="https://dukehorlogerie.com/first-edition" target="_blank" class="p1-platform"
                  ><i class="fal fa-external-link"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="section-owner jpadding" style="background-color: #232323;">
      <div class="grid-mywatch">
        <div class="mywatch-content mywatch-left">
          <div class="jbtn-watch-link">
            {{ $C.MODELS_INDEX[watch.model] }}
          </div>
          <img src="@/assets/watches/d8020.png" alt="" />
          <div class="mywatch-content jbtn-watch-link watch-right-serial">
            <small>SERIAL</small><br />
            {{ watch.serial }}
          </div>
        </div>

        <div class="mywatch-content mywatch-right">
          <h3>CERTIFICATE</h3>
          <div class="duke-text">
            <small>STATUS</small>
            <strong> {{ watch.status }}</strong>
          </div>
          <div class="duke-text">
            <small>WATCH ID</small>
            <strong> {{ watchId }}</strong>
          </div>
          <div class="duke-text">
            <small>MODEL ID</small>
            <strong> {{ watch.modelId }}</strong>
          </div>
          <div class="duke-text">
            <small>OWNER</small>
            <strong> {{ user.firstName + " " + user.lastName }}</strong>
          </div>
          <div class="duke-text">
            <small>PURCHASED ON</small>
            <strong> {{ $moment($C.getDateFromTimestamp(watch.purchasedOn)).format("DD/MM/YYYY") }}</strong>
          </div>
          <div class="duke-text">
            <small>PURCHASED AT</small>
            <strong> {{ watch.purchasedAt }}</strong>
          </div>
          <router-link :to="{ name: 'Manual' }" class="jbtn-watch-link text-gold">
            WATCH HISTORY
          </router-link>

          <h3>WARRANTY</h3>
          <div class="duke-text">
            <small>EXPIRY</small>
            <strong>
              {{
                $moment($C.getDateFromTimestamp(watch.warranties[watch.warrantyActive].end)).format(
                  "DD/MM/YYYY"
                )
              }}</strong
            >
          </div>
        </div>
      </div>
    </section> -->
  </div>
</template>

<script>
// import toast from "@/assets/js/toast";
import axios from "axios";
import moment from "moment";
import firebase from "@/firebase/firebaseInit";
import C from "@/const";
const db = firebase.db;
const auth = firebase.auth;

export default {
  name: "MyWatch",
  data() {
    return {
      showMenu:false,
      isLoading: false,
      isGeneratingPdf: false,
      userId: "",
      watchId: "",
      modelId: "",
      model: {
        id: "",
        name: "",
        assets: {
          front: "",
          logo: ""
        }
      },
      appointmentsList: [],
      servicesMap: new Map()
    };
  },
  methods: {
    getWatch: function(watchId) {
      db.collection("watches")
        .doc(watchId)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            const docdata = doc.data();
            docdata.id = doc.id;
            this.watches.push(docdata);

            //load watch model
            this.modelId = doc.data().modelId;
            this.getWatchModel();
          });
        })
        .catch(error => {
          console.log("Error getting watch: ", error);
        });
    },
    // LOAD watch model
    getWatchModel: function() {
      db.collection(C.COLLECTION.WATCH_CATALOG)
        .doc(this.modelId)
        .get()
        .then(doc => {
          const docdata = doc.data();
          docdata.id = doc.id;
          this.model = docdata;
        })
        .catch(error => {
          console.log("Error getting WATCH MODEL document: ", error);
        });
    },
    // LOAD services completed
    allAppointments: function() {
      this.isLoading = true;
      var appointmentsRef = db
        .collection(C.COLLECTION.APPOINTMENTS)
        .where("userId", "==", auth.currentUser.uid);
      appointmentsRef = appointmentsRef.where("watchId", "==", this.watchId);
      appointmentsRef = appointmentsRef.where("status", "==", C.STATUS.APPOINTMENT_STATUS.COMPLETED);

      appointmentsRef.orderBy("createdOn", "desc").onSnapshot(querySnapshot => {
        this.appointmentsList.splice(0);
        querySnapshot.forEach(appointmentDoc => {
          const appointment = appointmentDoc.data();
          appointment.id = appointmentDoc.id;
          this.appointmentsList.push(appointment);
        });
        console.log("APPOINTMENTS LOADED:");
        console.log(this.appointmentsList);
        this.isLoading = false;
      });
    },

    // GENERATE PDF
    generatePdf: function() {
      if (this.isGeneratingPdf) return;

      this.isGeneratingPdf = true;
      const pdfData = {
        request: "generate_certificate",
        responseType: "json",

        first_name: this.user.firstName,
        last_name: this.user.lastName,
        model_name: this.model.name,
        model_link: C.getModelLink(this.model.name), //TODO
        address: this.user.addr, //TODO
        serial: this.watch.serial,
        zip: this.user.zip, //TODO
        city: this.user.city, //TODO
        purchsed_on: this.watch.purchasedOn,
        country: this.user.country, //TODO
        warranty_expiry: moment(
          C.getDateFromTimestamp(this.watch.warranties[this.watch.warrantyActive].end)
        ).format("MMM YYYY"), //TO TEST
        purchsed_at: this.watch.purchasedAt,
        limited_edition: "1 of 8", //TODO
        warranty_1_start: moment(C.getDateFromTimestamp(this.watch.warranties[0].start)).format("MMM YYYY"),
        warranty_1_end: moment(C.getDateFromTimestamp(this.watch.warranties[0].end)).format("MMM YYYY"),
        warranty_1_status: C.STATUS.WARRANTY_STATUS_INDEX[this.watch.warranties[0].status],
        warranty_2_start: moment(C.getDateFromTimestamp(this.watch.warranties[1].start)).format("MMM YYYY"),
        warranty_2_end: moment(C.getDateFromTimestamp(this.watch.warranties[1].end)).format("MMM YYYY"),
        warranty_2_status: C.STATUS.WARRANTY_STATUS_INDEX[this.watch.warranties[1].status],
        warranty_3_start: moment(C.getDateFromTimestamp(this.watch.warranties[2].start)).format("MMM YYYY"),
        warranty_3_end: moment(C.getDateFromTimestamp(this.watch.warranties[2].end)).format("MMM YYYY"),
        warranty_3_status: C.STATUS.WARRANTY_STATUS_INDEX[this.watch.warranties[2].status],
        services: this.appointmentsList
      };
      console.log(pdfData);

      axios
        .post("/api/functions.php", pdfData)
        .then(response => {
          if (response.data != "") {
            console.log("PDF READY: /api/" + response.data);
            // saveAs(response.data, 'duke_certificate.pdf');

            const url = "/api/" + response.data;
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "duke_certificate.pdf");
            document.body.appendChild(link);
            link.click();
          } else {
            console.log("axios generate pdf: Empty response.");
          }
          this.isGeneratingPdf = false;
        })
        .catch(error => {
          console.log(error);
          this.isGeneratingPdf = false;
        });
    }
  },
  computed: {
    user: {
      get() {
        return this.$store.getters.getUser;
      }
    },
    watch: {
      get() {
        return this.$store.getters.getWatch;
      }
    }
  },
  created() {
    //init
    this.userId = auth.currentUser.uid;

    //get id from params
    if (this.$route.params.id != null && this.$route.params.id != "") {
      this.watchId = this.$route.params.id;
    }

    //load watch
    var watchRef = db.collection("watches").doc(this.watchId);
    watchRef.onSnapshot(doc => {
      this.$store.commit("setWatch", doc.data());
      //load watch model
      this.modelId = doc.data().modelId;
      this.getWatchModel();
    });
    // load appointments
    this.allAppointments();
    //load user
    var userDocRef = db.collection("users").doc(this.userId);
    userDocRef
      .get()
      .then(userDoc => {
        this.$store.commit("setUser", userDoc.data());
      })
      .catch(function(error) {
        console.log("Error getting USER document:", error);
      });
  }
};
</script>

<style scoped></style>
